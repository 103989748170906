.btn {
  @apply py-2 px-8;
  @apply inline-flex items-center justify-center space-x-2;
  @apply cursor-pointer;
  @apply no-underline;
  //@apply shadow;
  @apply relative;
  //@apply overflow-hidden;
  //@apply rounded-sm;
  transform: perspective(1px) translateZ(0);
  @apply transition-all duration-300 ease-in-out;
  @apply text-sm;

  svg {
    @apply w-5 h-5;
  }

  &:before {
    @apply content-[""];
    @apply absolute;
    @apply z-[-1];
    @apply rounded-sm;
    @apply top-0 left-0 right-0 bottom-0;
    @apply bg-dark;
    transform: scaleX(0);
    transform-origin: 0 50%;
    @apply transition-all duration-300 ease-in-out;
  }

  &:hover {
    @apply text-white;
    &:before {
      transform: scaleX(1);
    }
  }
}
