@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500;600&family=Nanum+Gothic:wght@400;700;800&display=swap");

@import "button";

::selection {
  background: theme("colors.primary");
}

html,
#root {
  @apply h-full;
}

body {
  @apply text-textColor;
  @apply font-Montserrat;
  @apply font-light;
  @apply bg-white;
  @apply min-h-full;
  @apply relative;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply font-normal;
}

.filler {
  @apply bg-primary;
  position: relative;
  overflow: hidden;
  z-index: 1;

  span {
    z-index: 1;
    position: relative;
  }

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 150%;
    height: 150%;
    border-radius: 50%;
    transform: translate3d(-50%, -50%, 0) scale3d(0, 0, 0);
    transition: opacity 0.4s cubic-bezier(0.19, 1, 0.22, 1),
      transform 0.75s cubic-bezier(0.19, 1, 0.22, 1);
    opacity: 0;
    @apply bg-dark;
  }

  &:hover {
    span {
      @apply text-white;
    }

    &:before {
      opacity: 1;
      transition-duration: 0.85s;
      transform: translate3d(-50%, -50%, 0) scale3d(1.2, 1.2, 1.2);
    }
  }
}

.swiper-wrapper {
  @apply flex items-center;
}
